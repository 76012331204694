import React from 'react'

const Configuration = React.lazy(() => import('./views/Configuration'))
const CallHistory = React.lazy(() => import('./views/CallHistory'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/Config', name: 'Configuration', element: Configuration },
  { path: '/CallHistory', name: 'Missed Calls', element: CallHistory, exact: true },
]

export default routes
